<template>
  <Component
    :is="$attrs.href ? 'a' : 'button'"
    :class="[disabled ? 'shadow' : '', buttonClass]"
    :disabled="disabled"
    v-bind="$attrs"
  >
    <slot />
  </Component>
</template>

<script setup lang="ts">
import { computed } from 'vue';

const props = withDefaults(
  defineProps<{
    color?: string,
    size?: string,
    disabled?: boolean,
  }>(),
  {
    color: 'primary',
    size: 'md',
    disabled: false,
  },
);

const colorMatch = (value: string) => new Map([
  ['secondary', 'border-transparent text-primary-700 bg-primary-100 hover:bg-primary-200'],
  ['gray-outline', 'border-gray-300 text-gray-700 bg-white hover:bg-gray-50'],
  ['red-outline', 'border-red-300 text-red-700 bg-white hover:bg-red-50'],
  ['green', 'border-transparent text-white bg-green-600 hover:bg-green-700'],
  ['transparent', 'border-transparent text-gray-700 bg-white hover:text-gray-900 !shadow-none'],
  ['indigo', 'border-transparent text-white bg-indigo-600 hover:bg-indigo-700'],
  ['white', 'border-transparent text-primary-600 bg-white hover:bg-indigo-50'],
  ['red', 'border-transparent text-red-700 bg-red-100 hover:bg-red-200'],
  ['disabled', 'border-gray-300 text-gray-700 bg-gray-50'],
  ['blue', 'border-transparent bg-blue-100 text-blue-800'],
  ['yellow', 'border-transparent bg-yellow-100 text-yellow-800 hover:bg-yellow-200'],
  ['dark-blue', 'border-transparent text-white bg-primary-500 hover:bg-primary-700'],
  ['user-actions', 'rounded-none border-transparent !shadow-none font-light w-full px-4 py-2 text-left text-base lg:text-sm leading-5 text-gray-700 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 transition duration-150 ease-in-out'],
  ['none', 'border-transparent'],
]).get(value) ?? 'border-transparent text-white bg-primary-600 hover:bg-primary-700';

const sizeMatch = (value: string) => new Map([
  ['sm', 'px-3 py-2 text-sm leading-4'],
  ['lg', 'px-8 py-3 text-lg md:py-4 md:text-lg md:px-10'],
]).get(value) ?? 'px-4 py-2 text-sm';

const buttonClass = computed(() => `inline-flex items-center rounded-md shadow-sm border ${colorMatch(props.color)} ${sizeMatch(props.size)}`);
</script>
